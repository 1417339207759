// These discounts are promo codes that are automatically applied to certain pass types
// If no discounts currently exist, just export an empty array

import { PURCHASE_TYPES } from "./constants";

export const phlDiscounts = [
  {
    subscriptionTypeId: 251, // Pass type ID
    displayPrice: "24 hours of access for <s>$15</s> <b>$5</b>",
    promo: "FIRSTFRIDAY", // Promo code to automatically apply
    discountText: `<strong>First Friday discount!</strong><br/>
     Brough to you by<br/>
    <img 
      width='122' 
      height='30' 
      style='margin-top:0.5em' 
      src='https://federaldonuts.com/wp-content/uploads/2024/03/logo-mobile.svg' 
      alt='Federal Donuts & Chicken' />`, // to be shown in pass description
    shouldApply: () => {
      const today = new Date();
      return today < new Date("2025-01-01") && today.getDay() === 5 && today.getDate() <= 7;
    },
    purchaseTypes: [PURCHASE_TYPES.new, PURCHASE_TYPES.change, PURCHASE_TYPES.renew],
  },
  {
    subscriptionTypeId: 239,
    displayPrice: "<s>$20</s> <b>$5</b> for the first month",
    promo: "INDEPRO24", // Promo code to automatically apply
    discountText: "Bike month discount!", // to be shown in pass description
    shouldApply: () => {
      const today = new Date();
      return today < new Date("2024-06-01");
    },
    purchaseTypes: [PURCHASE_TYPES.new],
  },
  {
    subscriptionTypeId: 252,
    discountText: "<span class='pass-extension-promo'>+3 bonus months when you buy today</span><br/><span class='pass-extension-promo-extra'>$39 savings</span><br><span class='pass-extension-promo-terms'>Time will be added automatically within 3 days</span>",
    shouldApply: () => {
      const today = new Date();
      return today < new Date("2024/12/03") && today >= new Date("2024/11/29");
    },
    purchaseTypes: [PURCHASE_TYPES.change, PURCHASE_TYPES.renew, PURCHASE_TYPES.new],
  },
  {
    subscriptionTypeId: 273,
    discountText: "<span class='pass-extension-promo'>+3 bonus months when you buy today</span><br/><span class='pass-extension-promo-extra'>$57.50 savings</span><br><span class='pass-extension-promo-terms'>Time will be added automatically within 3 days</span>",
    shouldApply: () => {
      const today = new Date();
      return today < new Date("2024/12/03") && today >= new Date("2024/11/29");
    },
    purchaseTypes: [PURCHASE_TYPES.change, PURCHASE_TYPES.renew, PURCHASE_TYPES.new],
  },
  {
    subscriptionTypeId: 254,
    discountText: "<span class='pass-extension-promo'>+3 bonus months when you buy today</span><br/><span class='pass-extension-promo-extra'>$12 savings</span><br><span class='pass-extension-promo-terms'>Time will be added automatically within 3 days</span>",
    shouldApply: () => {
      const today = new Date();
      return today < new Date("2024/12/03") && today >= new Date("2024/11/29");
    },
    purchaseTypes: [PURCHASE_TYPES.change, PURCHASE_TYPES.renew, PURCHASE_TYPES.new],
  },
  {
    subscriptionTypeId: 274,
    discountText: "<span class='pass-extension-promo'>+3 bonus months when you buy today</span><br/><span class='pass-extension-promo-extra'>$24.99 savings</span><br><span class='pass-extension-promo-terms'>Time will be added automatically within 3 days</span>",
    shouldApply: () => {
      const today = new Date();
      return today < new Date("2024/12/03") && today >= new Date("2024/11/29");
    },
    purchaseTypes: [PURCHASE_TYPES.change, PURCHASE_TYPES.renew, PURCHASE_TYPES.new],
  },
];
