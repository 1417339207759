/* eslint-disable indent */

import Honeybadger from "@honeybadger-io/js";
import { PASS_DURATION, PURCHASE_TYPES } from "./systemData";
import { useAccount } from "./stores/account";
import { reducedFareTypes, notEligible } from "./components/ReducedFare/reducedFareTypes";

export function displayDateFormat(inputDate) {
  if (!inputDate) return "";
  return new Date(inputDate).toLocaleDateString("default", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function displayPassDuration(
  pass,
  { includeOfAccess = false } = { includeOfAccess: false },
) {
  let returnVal = "";
  switch (pass.duration) {
    case PASS_DURATION.single:
      return "one ride";
    case PASS_DURATION.day:
      returnVal = "24 hours";
      break;
    case PASS_DURATION.threeDays:
      returnVal = "3 days";
      break;
    case PASS_DURATION.week:
      returnVal = "7 days";
      break;
    case PASS_DURATION.month:
      returnVal = "30 days";
      break;
    case PASS_DURATION.fiveMonths:
      returnVal = "5 months";
      break;
    case PASS_DURATION.sixMonths:
      returnVal = "6 months";
      break;
    case PASS_DURATION.year:
      returnVal = "365 days";
      break;
    default:
  }
  return includeOfAccess ? `${returnVal} of access` : returnVal;
}

export function displayPassName({ name }) {
  return `${name} pass`;
}

export function getReducedFareValidationText(system) {
  const _reducedFareTypes = reducedFareTypes[system].filter(
    (validationType) => validationType.type !== notEligible.type,
  );
  if (_reducedFareTypes.length <= 1) {
    return _reducedFareTypes[0].description.replace("For", "");
  }
  return _reducedFareTypes
    .map((t, idx, arr) => {
      if (idx === arr.length - 1) {
        return `and ${t.description.replace("For", "")}`;
      }
      return t.description.replace("For", "");
    })
    .join(", ");
}

export const getPassFieldDisplayFormats = (
  {
    markCurrent = false,
    markJustPurchased = false,
    purchaseType = undefined,
    reducedFareValidationText = undefined,
    useDisplayPrice = false,
  } = {
    markCurrent: false,
    markJustPurchased: false,
    purchaseType: undefined,
    reducedFareValidationText: false,
    useDisplayPrice: false,
  },
) => {
  let passFields = [
    {
      field: "displayPrice",
      icon: "fa-dollar-sign.svg",
      getter: (pass) =>
        useDisplayPrice ? getPassPurchaseSubheader(pass, PURCHASE_TYPES.new) : undefined,
      displayName: "Price",
    },
    {
      field: "discounts",
      icon: "fa-dollar-sign.svg",
      getter: (pass) => {
        const discounts = getPassDiscountsForPurchaseType(pass, purchaseType);
        return discounts?.[0]?.discountText;
      },
      displayName: "discounts",
    },
    {
      field: "extraBenefits",
      icon: "fa-plus.svg",
      getter: (pass) => pass.extraBenefits,
      displayName: "extra benefits",
    },
    {
      field: "baseBenefits",
      icon: "fa-bicycle.svg",
      getter: (pass) => pass.baseBenefits,
      displayName: "base benefits",
    },
    {
      field: "extendedRideFees",
      icon: "fa-hourglass-end.svg",
      getter: (pass) => pass.extendedRideFees,
      displayName: "extended ride fees",
    },
    {
      field: "eBikeFees",
      icon: "fa-bolt.svg",
      getter: (pass) => pass.eBikeFees,
      displayName: "ebike fees",
    },
  ];

  if (reducedFareValidationText) {
    passFields = passFields.concat([
      {
        getter: (pass) =>
          pass.discountVerificationType ? `For ${reducedFareValidationText} only` : undefined,
        icon: "fa-star.svg",
        displayName: "eligibility",
      },
    ]);
  }

  if (markCurrent) {
    passFields.unshift({
      // Whether pass is the current pass, upcoming pass
      field: "id",
      icon: "fa-star.svg",
      getter: (pass) => {
        const account = useAccount();
        if (!account.user) {
          return;
        }
        if (pass.id === account.user.subscriptionTypeId) {
          if (account.currentSubscriptionExpired) {
            return "Your most recent pass";
          } else {
            return "Your current pass";
          }
        }
      },
      displayName: "current pass",
    });
  } else if (markJustPurchased) {
    passFields.unshift({
      // Whether pass is the current pass, upcoming pass
      field: "id",
      icon: "fa-star.svg",
      getter: (pass) => {
        const account = useAccount();
        if (pass.id === account.cart.subscriptionTypeId) {
          return "Your new pass";
        }
      },
      displayName: "new pass",
    });
  }

  passFields = passFields.concat([{
    field: "terms",
    getter: (pass) => pass.terms,
    displayName: "terms",
  }]);

  return passFields;
};

export function sendAppMessage({ action, parameters }) {
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.appMessageHandler
  ) {
    const message = {};
    message[action] = parameters;
    try {
      window.webkit.messageHandlers.appMessageHandler.postMessage(
        JSON.stringify({
          data: message,
        }),
      );
    } catch (e) {
      console.warn(e);
    }
  }
  if (window.AndroidAppMessage && action in window.AndroidAppMessage) {
    try {
      window.AndroidAppMessage[action](...Object.values(parameters));
    } catch (e) {
      console.warn(e);
    }
  }
}

export function asUrlParams(values) {
  const params = new URLSearchParams();
  Object.entries(values).forEach(([key, value]) => {
    params.append(key, value);
  });
  return params;
}

export function isDateNumberOfYearsAgo(checkDate, numberOfYears = 62) {
  const comparisonDate = new Date();
  comparisonDate.setFullYear(new Date().getFullYear() - numberOfYears);
  if (new Date(checkDate) < comparisonDate) {
    return true;
  }
  return false;
}

export function formatPhoneNumberForDisplay(numberWithCountryCode) {
  // Expects format "+18444463346" as input. See systemData support phone
  const firstThree = numberWithCountryCode.slice(2, 5);
  const nextThree = numberWithCountryCode.slice(5, 8);
  const lastThree = numberWithCountryCode.slice(8);
  return [firstThree, nextThree, lastThree].join("-");
}

export function formatCurrency(amount) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
}

export function camelCaseToReadableText(inputString) {
  return inputString.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
}

export function emailValid(inputEmail) {
  const re =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(inputEmail);
}

const ALLOWED_STUDENT_EMAIL_DOMAIN_REGEX =
  /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?([a-zA-Z0-9_.+-]+\.edu|afi\.com|lausd\.net)$/;
export function studentEmailValid(inputEmail) {
  if (!inputEmail) {
    return false;
  }
  return ALLOWED_STUDENT_EMAIL_DOMAIN_REGEX.test(inputEmail.toLowerCase());
}

export const SUGGESTED_DOMAINS = [
  "temple.edu",
  "bicycletransit.com",
  "sas.upenn.edu",
  "drexel.edu",
  "wharton.upenn.edu",
  "jefferson.edu",
  "qq.com",
  "udel.edu",
  "gmx.de",
  "seas.upenn.edu",
  "rocketmail.com",
  "cornell.edu",
  "web.de",
  "villanova.edu",
  "lacity.org",
  "usc.edu",
  "sbcglobal.net",
  "metro.net",
  "ladwp.com",
  "dot.ca.gov",
  "naver.com",
  "caltech.edu",
  "google.com",
  "ucla.edu",
  "hey.com",
  "afi.com",
];

export function getConfirmationDateInfo(account, purchased) {
  // If there is a not yet expired subscription and it has an expiration date
  // Whether purchased or not
  if (account.currentSubscriptionExpirationDate) {
    if (account.cart?.proratedDiscount || account.justPurchasedCart?.proratedDiscount) {
      return "Your previous pass will expire shortly. You will receive an email when your new pass is active.";
    }
    const currentExpirationDate = displayDateFormat(account.currentSubscriptionExpirationDate);
    return `Your previous pass will remain active until ${currentExpirationDate}. Your new pass will be available after that.`;
  }

  // If they have not checked out yet and there isn't a currently active subscription with an expiration date
  if (!purchased && !account.currentSubscriptionExpirationDate) {
    const expirationDate = displayDateFormat(account.userSummary.membershipExpiration);
    return `Your previous pass expired ${expirationDate}. Your new pass will be available immediately after purchase.`;
  }

  // If purchased and there wasn't an unexpired subscription with an expiration date
  return "Your new pass has been purchased sucessfully and is now available to use.";
}

const honeybadgerConfigured = import.meta.env.VITE_HONEYBADGER_API_KEY;
export function notifyHoneybadger(error) {
  if (honeybadgerConfigured) {
    Honeybadger.notify(error);
  } else {
    console.error("Honeybadger not configured");
  }
}

export function setHoneybadgerContext(context) {
  if (honeybadgerConfigured) {
    Honeybadger.setContext(context);
  }
}

// Promocodes include tax
export function getPreTaxValue(valueWithTax, currentSystem) {
  if (!currentSystem.salesTax) {
    return valueWithTax;
  }
  // Return if system has no sales tax
  return Math.round((valueWithTax * 100) / (1 + currentSystem.salesTax)) / 100;
}

export async function userTestGroup(user) {
  if (import.meta.env.VITE_MOCK_USE_ACCOUNT && window.location.search.includes("mock=true")) {
    // TODO: DITCH AFTER PILOT
    return 5;
  }
  if (!window?.crypto?.subtle?.digest) {
    return 255;
  }
  const textEncoder = new TextEncoder();
  const encoded = textEncoder.encode(user.userName);
  const cryptoSubtleVersion = await window.crypto.subtle.digest("SHA-256", encoded);
  const typedArray = new Uint8Array(cryptoSubtleVersion);
  // Then convert to a decimal and that is their "trial group"
  const hash = Array.from(typedArray).map((b) => b.toString(10).padStart(2, "0"));
  // Take the left 2 digits of the SHA256(username)
  return hash[0];
}

export function redirectToAppLogin(navigate, event, { username } = {}) {
  sendAppMessage({
    action: "login",
    parameters: {
      username,
    },
  });
  navigate(event);
}

export function getPassDiscountsForPurchaseType(pass, purchaseType) {
  return pass?.discounts?.filter((d) => d.purchaseTypes.includes(purchaseType));
}

export function getPassPurchaseSubheader(pass, purchaseType) {
  if (!pass?.discounts?.length) {
    return pass?.displayPrice;
  }
  const passDiscountsForPurchaseType = getPassDiscountsForPurchaseType(pass, purchaseType);
  if (!passDiscountsForPurchaseType?.length) {
    return pass.displayPrice;
  }
  return passDiscountsForPurchaseType[0]?.displayPrice || pass.displayPrice;
}

export function metaPixelTrackPurchase({ justPurchasedCart, justPurchasedCost }) {
  if (window.fbq) {
    const contents = [
      {
        id: justPurchasedCart.subscriptionTypeId,
        quantity: 1,
      },
    ];
    // if there is a promo code, log that as well
    if (justPurchasedCart.promocodeDetails?.code) {
      contents.push({
        id: justPurchasedCart.promocodeDetails?.code,
        quantity: 1,
      });
    }
    window.fbq("track", "Purchase", {
      value: justPurchasedCost,
      currency: "USD",
      contents,
    });
  }
}
