import { PASS_DURATION, PASS_FAMILY } from "./constants";

export const currentPhlPasses = [
  {
    id: 238,
    name: "Single Ride",
    displayPrice: "$4.50",
    terms: "Pass begins when you take your first ride",
    baseBenefits: "One 30-minute ride on any classic bike",
    eBikeFees: "Electric bikes: Extra 30¢/minute to unlock",
    extendedRideFees: "Rides over 30 minutes: Extra 30¢/minute",
    price: 4.5,
    salesTax: 0.08,
    cardDefault: null,
    isAutoRenewing: false,
    duration: PASS_DURATION.single,
    family: PASS_FAMILY.casual,
  },
  {
    id: 251,
    name: "Guest",
    displayPrice: "24 hours of access for $15",
    terms: "Pass begins when you take your first ride",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 20¢/minute to unlock",
    extendedRideFees: "Rides over 60 minutes: Extra 20¢/minute",
    price: 15,
    salesTax: 0.08,
    cardDefault: null,
    isAutoRenewing: false,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.casual,
  },
  {
    id: 239,
    name: "Indego30",
    displayPrice: "$20/month",
    terms: "Renews every 30 days. Cancel anytime",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 20¢/minute to unlock",
    extendedRideFees: "Rides over 60 minutes: Extra 20¢/minute",
    price: 20,
    salesTax: 0.08,
    cardDefault: false,
    cardCost: 5,
    isAutoRenewing: true,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.member,
  },
  {
    id: 252,
    name: "Indego365",
    displayPrice: "$13/month ($156 billed annually)",
    terms: "Renews every 365 days. Cancel anytime",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 20¢/minute to unlock",
    extendedRideFees: "Rides over 60 minutes: Extra 20¢/minute",
    price: 156,
    salesTax: 0.08,
    cardDefault: false,
    isAutoRenewing: true,
    class: "best",
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
  },
  {
    id: 273,
    name: "Indego365 Plus",
    displayPrice: "$19.17/month ($230 billed annually)",
    terms: "Renews every 365 days. Cancel anytime",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 20¢/min after your two included daily rides",
    extendedRideFees: "Rides over 60 minutes: Extra 20¢/minute",
    extraBenefits: "Two 30-minute electric bike rides included daily",
    price: 230,
    salesTax: 0.08,
    cardDefault: null,
    isAutoRenewing: true,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
    prorateOnChange: true,
  },
  {
    id: 243,
    name: "Indego30 Access",
    terms: "Renews every 30 days. Cancel anytime",
    displayPrice: "$5/month",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 7¢/minute to unlock",
    extendedRideFees: "Rides over 60 minutes: Extra 7¢/minute",
    price: 5,
    salesTax: 0.08,
    discountVerification: 123,
    discountVerificationType: "access",
    cardDefault: false,
    isAutoRenewing: true,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.member,
  },
  {
    id: 254,
    name: "Indego365 Access",
    displayPrice: "$4/month ($48 billed annually)",
    terms: "Renews every 365 days. Cancel anytime",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 7¢/minute to unlock",
    extendedRideFees: "Rides over 60 minutes: Extra 7¢/minute",
    price: 48,
    salesTax: 0.08,
    discountVerification: 124,
    discountVerificationType: "access",
    cardDefault: false,
    isAutoRenewing: true,
    class: "best",
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
  },
  {
    id: 274,
    name: "Indego365 Plus Access",
    displayPrice: "$8.33/month ($100 billed annually)",
    terms: "Renews every 365 days. Cancel anytime",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 7¢/min after your two included daily rides",
    extendedRideFees: "Rides over 60 minutes: Extra 7¢/minute",
    extraBenefits: "Two 30-minute electric bike rides included daily",
    price: 100,
    salesTax: 0.08,
    discountVerification: 124,
    discountVerificationType: "access",
    cardDefault: null,
    isAutoRenewing: true,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
    prorateOnChange: true,
    relatedFullFarePassId: 273,
  },
];

export const notForPublicPurchasePhlPasses = [
  {
    id: 244,
    name: "Ambassador Pass",
    baseBenefits: "",
    displayPrice: "$0/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 0,
    salesTax: 0.08,
  },
  {
    id: 246,
    name: "Indego30 Discount",
    baseBenefits: "Unlimited one hour rides<br>20¢/minute after one hour",
    displayPrice: "$12/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 12,
    salesTax: 0.08,
  },
  {
    id: 247,
    name: "Staff Pass",
    baseBenefits: "Unlimited rides just for Bicycle Transit employees",
    displayPrice: "$0/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 0,
    salesTax: 0.08,
  },
  {
    id: 256,
    visible: false,
    name: "B4B Testing",
    displayPrice: "$0",
    terms: "Free Indego Key $4/month (billed annually)",
    baseBenefits: "Unlimited 60-minute rides on any classic bike",
    eBikeFees: "Electric bikes: Extra 5¢/minute to unlock",
    extendedRideFees: "Extra 5¢/minute after the first 60 minutes",
    price: 0,
    salesTax: 0.08,
    discountVerification: 124,
    discountVerificationType: "business",
    cardDefault: null,
    isAutoRenewing: true,
    class: "best",
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.restricted,
  },
];

export const deprecatedPhlPasses = [
  {
    id: 241,
    name: "IndegoFlex",
    baseBenefits: "$10/year<br>20¢/minute for all rides",
    displayPrice: "$10/year",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 252,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 240,
    name: "Indego30",
    baseBenefits: "Unlimited one hour rides<br>20¢/minute after one hour",
    displayPrice: "$20/month",
    isAutoRenewing: true,
    visible: false,
    deprecatedTo: 239,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 242,
    name: "Digital Skills and Bicycle Thrills Pass",
    baseBenefits: "Free for 6 months",
    displayPrice: "$0/month",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 239,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 245,
    name: "Indego30 Access",
    baseBenefits: "Unlimited 1 hour rides<br>7¢/minute for rides over 1 hour",
    displayPrice: "$5/month",
    isAutoRenewing: true,
    visible: false,
    deprecatedTo: 243,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 272,
    name: "Workforce Commuter Pass",
    baseBenefits: "Unlimited one hour rides<br>20¢/minute after one hour",
    displayPrice: "$6 for 6 months",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 239,
    duration: PASS_DURATION.sixMonths,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 249,
    name: "Indego30 Corporate Pass",
    baseBenefits: "Unlimited one hour rides<br>20¢/minute after one hour",
    displayPrice: "$0/month",
    isAutoRenewing: true,
    visible: false,
    deprecatedTo: 239,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 0,
    salesTax: 0.08,
  },
  {
    id: 250,
    name: "Indego30 Corporate Pass",
    baseBenefits: "Unlimited one hour rides<br>20¢/minute after one hour",
    displayPrice: "$6/month",
    isAutoRenewing: true,
    visible: false,
    deprecatedTo: 239,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 6,
    salesTax: 0.08,
  },
  {
    id: 275,
    name: "Courtesy Pass",
    baseBenefits: "Free access for 7 days",
    terms: "Pass begins when you take your first ride",
    displayPrice: "$0",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 239,
    duration: PASS_DURATION.week,
    family: PASS_FAMILY.restricted,
    price: 0,
    salesTax: 0.08,
  },
];
