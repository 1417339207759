import { PASS_DURATION, PASS_FAMILY } from "./constants";

export const currentLaxPasses = [
  {
    id: 2310115,
    name: "1-Ride",
    displayPrice: "$1.75/30 Minutes",
    baseBenefits: "All rides are $1.75 every 30 minutes",
    eBikeFees: "$1 to unlock an ebike",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    price: 1.75,
    cardDefault: null,
    isAutoRenewing: false,
    duration: PASS_DURATION.single,
    family: PASS_FAMILY.casual,
  },
  {
    id: 2310122,
    name: "24-Hour Access",
    displayPrice: "$5 to start",
    baseBenefits: "All rides 30 minutes or less are free",
    eBikeFees: "$1 to unlock an ebike",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    price: 5,
    cardDefault: null,
    isAutoRenewing: false,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.casual,
  },
  {
    id: 2310130,
    name: "30-Day",
    displayPrice: "$17/month",
    baseBenefits: "All rides 30 minutes or less are free",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    eBikeFees: "No ebike fees",
    price: 17,
    cardDefault: null,
    isAutoRenewing: true,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.member,
  },
  {
    id: 2310126,
    name: "365-Day",
    displayPrice: "$150/year",
    baseBenefits: "All rides 30 minutes or less are free",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    eBikeFees: "No ebike fees",
    price: 150,
    cardDefault: null,
    isAutoRenewing: true,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
  },
  {
    visible: true,
    id: 2310131,
    name: "30-Day Reduced Fare",
    displayPrice: "$5/month",
    baseBenefits: "All rides 30 minutes or less are free",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    eBikeFees: "No ebike fees",
    discountVerificationType: "metro_rf",
    discountVerification: 123,
    price: 5,
    cardDefault: null,
    isAutoRenewing: true,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.member,
  },
  {
    visible: true,
    id: 2310132,
    name: "365-Day Reduced Fare",
    displayPrice: "$50/year",
    baseBenefits: "All rides 30 minutes or less are free",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    eBikeFees: "No ebike fees",
    discountVerificationType: "metro_rf",
    discountVerification: 123,
    price: 50,
    cardDefault: null,
    isAutoRenewing: true,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.member,
  },
  {
    visible: false,
    id: 2310133,
    name: "Bikeshare for Business",
    displayPrice: "$0/year",
    baseBenefits: "All rides 30 minutes or less are free",
    extendedRideFees: "$1.75 per 30 minutes after the first half hour",
    eBikeFees: "No ebike fees",
    discountVerificationType: "business",
    discountVerification: 123,
    price: 0,
    cardDefault: null,
    isAutoRenewing: true,
    class: "best",
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.restricted,
  },
];

export const notForPublicPurchaseLaxPasses = [
  {
    id: 2310098,
    name: "Staff Pass",
    baseBenefits: "All trips are free for us",
    displayPrice: "$0/year",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.restricted,
    price: 0,
  },
  {
    id: 2310099,
    name: "Business Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$0/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 0,
  },
  {
    id: 2310100,
    name: "Business Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$2.50/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 2.5,
  },
  {
    id: 2310101,
    name: "Business Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$5/month",
    isAutoRenewing: true,
    visible: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
    price: 5,
  },
];

export const deprecatedLaxPasses = [
  {
    id: 240,
    name: "Flex Pass",
    baseBenefits: "All trips 30 minutes or less are $1.75",
    extendedRideFees: "$1.75 per 30 minutes thereafter",
    displayPrice: "$40/year",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 2310115,
    tapEnabled: false,
    duration: PASS_DURATION.year,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 2310102,
    name: "24-Hour Access Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$5/day",
    isAutoRenewing: false,
    visible: false,
    tapEnabled: false, // is tap enabled, but allowing to move to a non-tap pass now
    deprecatedTo: 2310122,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 2310103,
    name: "LATTC Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$25 for 5 months",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 239,
    tapEnabled: false,
    duration: PASS_DURATION.fiveMonths,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 2310106,
    name: "1-Ride",
    baseBenefits: "$1.75/30 minutes",
    displayPrice: "$1.75 per trip",
    isAutoRenewing: false,
    visible: false,
    tapEnabled: false,
    deprecatedTo: 2310115,
    duration: PASS_DURATION.single,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 2310105,
    name: "Student Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$5/month",
    isAutoRenewing: false,
    visible: false,
    deprecatedTo: 239,
    tapEnabled: false,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 2310111,
    name: "24-Hour Access Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 every 30 minutes thereafter",
    displayPrice: "$5/day",
    isAutoRenewing: false,
    visible: false,
    tapEnabled: false, // is tap enabled, but allowing to move to a non-tap pass now
    deprecatedTo: 2310122,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 239,
    name: "30-Day Pass",
    baseBenefits: "All trips 30 minutes or less are free",
    extendedRideFees: "$1.75 per 30 minutes thereafter",
    displayPrice: "$17/month",
    isAutoRenewing: true,
    visible: false,
    tapEnabled: false,
    deprecatedTo: 2310130,
    duration: PASS_DURATION.month,
    family: PASS_FAMILY.restricted,
  },
  {
    id: 2310137,
    name: "Courtesy Pass",
    baseBenefits: "Free access for 24-hours",
    terms: "Pass begins when you take your first ride",
    displayPrice: "$0",
    isAutoRenewing: false,
    visible: false,
    tapEnabled: false,
    deprecatedTo: 2310130,
    duration: PASS_DURATION.day,
    family: PASS_FAMILY.restricted,
  },
];
